//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildingsAdmin {
  
  sudo(buildingsAdmin_data) {
    return http.post( api.building_admins.sudo.endpoint ,buildingsAdmin_data);
  }

  add_admin(buildingsAdmin_data) {
    return http.post( api.building_admins.add_admin.endpoint,buildingsAdmin_data );
  }

  list(params){
    return http.get( api.building_admins.get.endpoint , params);
  }

  deletAdmin(buildingAdminUuid){
    return http.delete( api.building_admins.delete.endpoint + buildingAdminUuid );
  }
 

}

export default new buildingsAdmin();